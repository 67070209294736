var GenreFactory = angular.module('GenreFactory', ['EntityFactory','GenreSubCollectionFactory']);

GenreFactory.factory('Genre', ['Entity','GenreSubCollection', function (Entity, GenreSubCollection) {
	var url = '/api/v1/genres';

	var Genre = function (data) {
		Entity.apply(this, [url, data]);


        if  (! this.genre_subs) { this.genre_subs = {'data' : []}; }
        this.genreSubCollection = new GenreSubCollection(this.genre_subs.data);

        var hThis = this;

        this.genreSubCollection.getFilters = function() {
            return {'genre_id' : hThis.id };
        };
	};

	Genre.prototype = new Entity(url);

	Genre.prototype.loadChildren = function() {
	    /*
	    console.log("This: " + JSON.stringify(this));
	    if (this.genre_subs_count > 0)
            this.genreSubCollection.get(true,false);
        */
    };

    return Genre;
}]);