var MusicUseFactory = angular.module('MusicUseFactory', ['EntityFactory']);

MusicUseFactory.factory('MusicUse', ['Entity', function (Entity) {
	var url = '/api/v1/music_uses';

	var MusicUse = function (data) {
		Entity.apply(this, [url, data]);
	};

	MusicUse.prototype = new Entity(url);

    return MusicUse;
}]);