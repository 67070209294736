var SiteApp = angular.module('YessianSiteApp', ['ngRoute', 'ui.select', 'ui.bootstrap', 'ngAnimate', 'checklist-model', 'ngTagsInput', 'angularSoundManager', 'rzSlider', 'shagstrom.angular-split-pane', 'EntityFactory', 'EntityCollectionFactory', 'PleaseWaitModal', 'ConfirmationModal']);

SiteApp.controller('SiteController', ['$window', '$http', '$scope', '$location',
    function ($window, $http, $scope, $location) {
        $scope.logout = function ($event) {
            history.pushState('', document.title, window.location.origin);
            $http({
                method: 'GET',
                url: '/auth/logout',
                headers: {"Content-Type": "application/json"},
                data: '{}'
            }).success(function (data) {
                window.location.replace('/');
            }).error(function (data) {
                window.location.replace('/');
            })
        };

        $scope.scroll_top = function () {
            $(window).scrollTop(0);
        };

        $scope.hideScrollBtn = function () {
            return $window.pageYOffset < $window.innerHeight;
        };

        $window.onscroll = function () {
            $scope.$apply();
        };
    }]);

SiteApp.controller('HomeController', function ($scope) { });

SiteApp.config(function ($routeProvider, $locationProvider) {
    $routeProvider

        .when('/', {
            templateUrl: '/home',
            controller: 'HomeController'
        })

        .when('/login', {
            templateUrl: '/views/login',
            controller: 'HomeController'
        })

        .when('/my/reset-password', {
            templateUrl: '/password/email',
            controller: 'HomeController'
        })


        .when('/about/:id', {
            templateUrl: function (params) {
                return '/views/about/' + params.id;
            },
            controller: 'HomeController'
        })


        .when('/about', {
            templateUrl: '/views/about',
            controller: 'HomeController'
        })

        .when('/news', {
            templateUrl: '/views/news',
            controller: 'HomeController'
        })
/*
        .when('/services', {
            templateUrl: '/views/services'
        })

        .when('/services/:id', {
            templateUrl: function (params) {
                return '/views/services/' + params.id;
            },
            controller: 'HomeController'
        })
*/

        .when('/register', {
            templateUrl: '/auth/register',
            controller: 'HomeController'
        })


        .when('/playlists/:id', {
            templateUrl: function (params) {
                return '/views/playlists/' + params.id;
            },
            controller: 'HomeController'
        })

        .when('/medias/:id', {
            templateUrl: function (params) {
                return '/views/medias/' + params.id;
            }
        })

        .when('/reels/:id', {
            templateUrl: function (params) {
                return '/views/reels/' + params.id;
            }
        })

        .when('/links/:id', {
            templateUrl: function (params) {
                return '/views/links/' + params.id;
            }
        })

        .when('/links/:id/:rest*\/', {
            templateUrl: function (params) {
                return '/views/links/' + params.id + '/' + params.rest;
            }
        })

        .when('/links/:id/login', {
            templateUrl: function (params) {
                return '/views/links/' + params.id + '/login';
            }
        })

        /*
        .when('/links/:id/:id2', {
            templateUrl: function (params) {
                return '/views/links/' + params.id + '/' + params.id2;
            },
        })
        */


        .otherwise({redirectTo: '/'});

    $locationProvider.html5Mode(true);
});


var SiteUtils = angular.module('YessianSiteUtils', []);
SiteUtils.directive('filterCb', function () {
    return {
        link: function (scope, element, attrs) {
            element.bind('click', function () {
                /*
$('input[type="checkbox"]').on('change', function() {
    $('input[name="' + this.name + '"]').not(this).prop('checked', false);
});
                 */
                if (element.context.checked)
                    element.parent().parent().addClass('active');
                else
                    element.parent().parent().removeClass('active');
            })
        },
    }
});

SiteUtils.directive('tabbedCb', function () {
    return {
        link: function (scope, element, attrs) {
            $(element).find('input[type="checkbox"]').on('change', function () {
                $('input[name="' + this.name + '"]').not(this).prop('checked', false);
            });
        },
    }
});

SiteUtils.directive('fireResize', function ($window) {
    return function (scope, element, attr) {

        var w = angular.element($window);

        w.bind('resize', function () {
            scope.$apply();
        });
    }
});

SiteUtils.directive('draggable', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element[0].addEventListener('dragstart', scope.dndHandler.handleDragStart, false);
            element[0].addEventListener('dragend', scope.dndHandler.handleDragEnd, false);
        }
    }
});

SiteUtils.directive('droppable', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element[0].addEventListener('drop', scope.dndHandler.handleDrop, false);
            element[0].addEventListener('dragover', scope.dndHandler.handleDragOver, false);
            element[0].addEventListener('dragleave', scope.dndHandler.handleDragLeave, false);
        }
    }
});

SiteApp.requires.push('YessianSiteUtils');

SiteUtils.getWindowWidth = function () {
    return window.innerWidth;
};

SiteUtils.matrixList = function (data, n) {
    var grid = [], i = 0, x = data.length, col, row = -1;
    for (var i = 0; i < x; i++) {
        col = i % n;
        if (col === 0) {
            grid[++row] = [];
        }
        grid[row][col] = data[i];
    }
    return grid;
};

SiteUtils.makeDownload = function (Url) {
    $('#contDownloads').append('<iframe style="display:none;" src="/' + Url + '"></iframe>');
};

SiteUtils.formatSeconds = function (Seconds, fAddZeros) {
    var sec_num = parseInt(Seconds, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (fAddZeros) {
        if (hours < 10) {
            hours = "0" + hours;
        }
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        if (seconds < 10) {
            seconds = "0" + seconds;
        }
    }
    return (hours != 0 ? hours + ':' : '') + minutes + ':' + seconds;
};

SiteUtils.alert = function (Title, Text, Options) {
    var Opts = $.extend({'title': Title, 'text': Text}, Options);

    Snarl.addNotification(Opts);
};

SiteUtils.cbAlert = function(Message, Result) {
    var Opts = $.extend({'title': "", 'text': Message}, {});
    Snarl.addNotification(Opts);
};

SiteUtils.setNav = function (Url) {
    $('body').injector().get('$location').path(Url);
    $('body').scope().$apply();
};

function _dynamicSort(property) {
    var sortOrder = 1;
    if (property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a, b) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

function _dynamicSortMultiple() {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    var props = arguments;
    return function (obj1, obj2) {
        var i = 0, result = 0, numberOfProperties = props.length;
        /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
        while (result === 0 && i < numberOfProperties) {
            result = _dynamicSort(props[i])(obj1, obj2);
            i++;
        }
        return result;
    }
}

Array.prototype.remove = function (from, to) {
    var rest = this.slice(parseInt(to || from) + 1 || this.length);
    this.length = from < 0 ? this.length + from : from;
    return this.push.apply(this, rest);
};

!function () {
    function _dynamicSortMultiple(attr) {
        /* dynamicSortMultiple function body comes here */
    }

    function _dynamicSort(property) {
        /* dynamicSort function body comes here */
    }

    Object.defineProperty(Array.prototype, "sortBy", {
        enumerable: false,
        writable: true,
        value: function () {
            return this.sort(_dynamicSortMultiple.apply(null, arguments));
        }
    });
}();