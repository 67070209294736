var InstrumentCollectionFactory = angular.module('InstrumentCollectionFactory', ['EntityCollectionFactory', 'InstrumentFactory']);

InstrumentCollectionFactory.factory('InstrumentCollection', ['EntityCollection', 'Instrument', function (EntityCollection, Instrument) {

	var url = '/api/v1/instruments';
	var name = 'instruments';

	var InstrumentCollection = function (initialInstrumentsData) {
		EntityCollection.apply(this, [url, name, Instrument, initialInstrumentsData]);
	};

	InstrumentCollection.prototype = new EntityCollection(url, name);

	return InstrumentCollection;

}]);