var MusicUseSubFactory = angular.module('MusicUseSubFactory', ['EntityFactory']);

MusicUseSubFactory.factory('MusicUseSub', ['Entity', function (Entity) {

	var url = '/api/v1/music_use_subs';

	var MusicUseSub = function (data) {
		Entity.apply(this, [url, data]);
	};

	MusicUseSub.prototype = new Entity(url);

	return MusicUseSub;
}]);