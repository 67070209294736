var EntityModal = angular.module('EntityModal', []);

EntityModal.service('EntityModal', ['$modal', function ($modal) {

	return {
		addEntity: function (entityCollection, templateUrl) {
			return $modal.open({
				templateUrl: templateUrl,
				controller: 'AddEntityModalController',
				size: 'lg',
				resolve: {
					entity: function () { return {}; },
					entityCollection: function () { return entityCollection; },
					parentCollection: function () { return []; }
				}
			});
		},

        addChildEntity: function (entity, entityCollection, parentCollection, templateUrl) {
            return $modal.open({
                templateUrl: templateUrl,
                controller: 'AddEntityModalController',
                size: 'lg',
                resolve: {
					entity: function () { return angular.copy(entity); },
                    entityCollection: function () { return entityCollection; },
					parentCollection: function () { return parentCollection; }
                }
            });
        },

		editEntity: function (entity, templateUrl) {
			return $modal.open({
				templateUrl: templateUrl,
				controller: 'EditEntityModalController',
				size: 'lg',
				resolve: {
					entity: function () { return angular.copy(entity); },
					parentCollection: function () { return {}; }
				}
			});
		},

        editChildEntity: function (entity, parentCollection, templateUrl) {
            return $modal.open({
                templateUrl: templateUrl,
                controller: 'EditEntityModalController',
                size: 'lg',
                resolve: {
                    entity: function () { return angular.copy(entity); },
					parentCollection: function () { return angular.copy(parentCollection); }
                }
            });
        }
	}

}]);

EntityModal.controller('AddEntityModalController', ['$scope', '$modalInstance','entity', 'entityCollection', 'parentCollection', function ($scope, $modalInstance, entity, entityCollection, parentCollection) {

	$scope.entity = entity;
	$scope.entityCollection = entityCollection;
	$scope.parentCollection = parentCollection;

	$scope.action = 'Add';

	$scope.ok = function() {
        //console.log(JSON.stringify($scope.entity));
		$scope.busy = true;
		$scope.errors = false;
		$scope.entityCollection.store($scope.entity).
			success(function (data) {
				$scope.busy = false;
				$modalInstance.close(data);
			}).error(function (data) {
				$scope.busy = false;
				$scope.errors = data.errors;
			});
	};

	$scope.cancel = function () {
		$modalInstance.dismiss('cancel');
	};

}]);


EntityModal.controller('EditEntityModalController', ['$scope', '$modalInstance', 'entity', 'parentCollection', function ($scope, $modalInstance, entity, parentCollection) {

	$scope.entity = entity;
    $scope.parentCollection = parentCollection;

    $scope.action = 'Edit';

	$scope.ok = function() {
		//console.log(JSON.stringify(entity));
		$scope.busy = true;
		$scope.errors = false;
		entity.update().
			success(function (data) {
				$scope.busy = false;
				$modalInstance.close(data);
			}).error(function (data) {
				$scope.busy = false;
				$scope.errors = data.errors;
			});
	};

	$scope.cancel = function () {
		$modalInstance.dismiss('cancel');
	};

}]);

EntityModal.directive('ngInitial', function() {
    return {
        restrict: 'A',
        controller: [
            '$scope', '$element', '$attrs', '$parse', function($scope, $element, $attrs, $parse) {
                var getter, setter, val;
                val = $attrs.ngInitial || $attrs.value;
                getter = $parse($attrs.ngModel);
                setter = getter.assign;
                setter($scope, val);
            }
        ]
    };
});
SiteApp.requires.push('EntityModal');