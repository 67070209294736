var EntityFactory = angular.module('EntityFactory', []);

EntityFactory.factory('Entity', ['$http', function($http) {

	var Entity = function (url, data) {
		this.url = url;
		this.busy = false;
		angular.extend(this, data);
	};

	Entity.prototype.addProperty = function (key, value)
	{
		if ( ! this[key] ) {
			this[key] = { 'data' : [] };
		}
		this[key].data.push(value);
	};


	Entity.prototype.removeProperty = function(key, value)
	{
		if ( ! this[key] ) { return;}
		var index = this[key].data.indexOf(value);
		if (index !== -1) {
			this[key].data.splice(index, 1);
		}
	};

	Entity.prototype.updateProperty = function(key, oldvalue, newvalue)
	{
		if ( ! this[key] ) { return;}
		var index = this[key].data.indexOf(oldvalue);
		if (index !== -1) {
			this[key].data.splice(index, 1);
			this[key].data.splice(index, 0, newvalue);
		}
	};


	Entity.prototype.store = function ()
	{
		this.busy = true;
		var self = this;
		return $http({
			method:'POST',
			url: this.url,
			headers: { "Content-Type": "application/json" },
			data: this
		}).success(function(data) {
			self.busy = false;
			angular.extend(this, data);
		}).error(function(data) {
			self.busy = false;
		});
	};



	Entity.prototype.update = function () {
		this.busy = true;
		var self = this;
		return $http({
			url: this.url + '/' + this.id,
			method: 'PUT',
			headers: { "Content-Type": "application/json"},
			data: this
		}).success(function (data) {
			self.busy = false;
			angular.extend(this, data);
		}).error(function () {
			self.busy = false;
		})
	};


	Entity.prototype.delete = function () {
		this.busy = true;
		var self = this;
		return $http({
			url: this.url + '/' + this.id,
			method: 'DELETE',
			headers: { "Content-Type": "application/json"}
		}).success(function () {
			self.busy = false;
		}).error(function () {
			self.busy = false;
		});
	};

	return Entity;

}]);