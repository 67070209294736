var TempoFactory = angular.module('TempoFactory', ['EntityFactory']);

TempoFactory.factory('Tempo', ['Entity', function (Entity) {

	var url = '/api/v1/tempos';

	var Tempo = function (data) {
		Entity.apply(this, [url, data]);
	};

	Tempo.prototype = new Entity(url);

	return Tempo;
}]);