var SitePlayerApp = angular.module('SitePlayerApp', ['ui.select', 'ui.bootstrap', 'ngAnimate', 'checklist-model', 'angularSoundManager']);

window.requestAnimFrame = function () {
    return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame || function (a) {
        window.setTimeout(a, 1E3 / 60)
    }
}();

SitePlayerApp.controller('SitePlayerController', ['$scope', '$anchorScroll', '$window', '$timeout', '$modal', 'angularPlayer', 'Track', 'PlaylistCollection', 'PleaseWaitModal', 'ConfirmationModal',
    function ($scope, $anchorScroll, $window, $timeout, $modal, angularPlayer, Track, PlaylistCollection, PleaseWaitModal, ConfirmationModal) {
        $scope.userPlaylistCollection = new PlaylistCollection([]);
        $scope.userPlaylistCollection.selectedCats = ['user_playlist'];
        $scope.userPlaylistCollection.get(true);

        $scope.Window = $window;
        $scope.isPlaying = false;
        $scope.currentPlaying = null;
        $scope.VolumeLevel = 100;

        $scope.playerVolumeSlider = {
            value: $scope.VolumeLevel,
            options: {
                floor: 0,
                ceil: 100,
                vertical: true,
                onChange: function (sliderId, modelValue, highValue, pointerType) {
                    angularPlayer.adjustVolumeSlider(modelValue);
                },
                translate: function (value) {
                    return "";
                },
                hideLimitLabels: true,
            }
        };

        $scope.renderSlider = function () {
            $timeout(function () {
                $scope.$broadcast('rzSliderForceRender');
            });
        };

        $scope.getPlaylistCount = function () {
            return angularPlayer.getPlaylistCount();
        };

        $scope.currentTrackData = function () {
            return angularPlayer.currentTrackData();
        };

        $scope.getWaveform = function (fIsMask) {
            var currentTrack = $scope.currentTrackData();
            if (currentTrack == null)
                return '';

            var mask = '';

            if (fIsMask)
                mask = '-mask';

            var Size = 'lg';
            var Width = window.innerWidth;
            if (Width <= 1280)
                Size = 'md';
            if (Width <= 767)
                Size = 'sm';

            return currentTrack.wf_url + '/' + currentTrack.code + '_' + Size + mask + '.png';
        };


        $scope.$on('music:isPlaying', function (event, data) {
            $scope.isPlaying = data;
            $timeout(function () {
                if (data) {
                    $scope.currentPlaying = $scope.currentTrackData();

                    //var WF = $scope.getWaveform(angularPlayer.currentTrackData());
                    //$('.img-player-wf').attr('src', WF + '.png');
                    //$('.img-player-wfm').attr('src', WF + '-mask.png');
                }
            });
        });

        $scope.downloadTrack = function () {
            if ($scope.currentPlaying == null)
                return;
            $modal.open({
                templateUrl: 'DownloadTrackModal',
                controller: 'DownloadTrackController',
                size: 'sm',
                resolve: {
                    track: function () {
                        return $scope.currentPlaying;
                    }
                }
            });
        };

        $scope.addToPlaylist = function () {
            var track = $scope.currentPlaying;
            if (track == null)
                return;

            var AddToPlaylistModal = $modal.open({
                templateUrl: 'addToUserPlaylistModal',
                controller: 'AddToPlaylistController',
                size: 'lg',
                resolve: {
                    track: function () {
                        return track;
                    },
                    playlists: function () {
                        return $scope.userPlaylistCollection;
                    }
                }
            }).result.then(function (data) {
                var order = 1;
                if (!(typeof data === 'string' || !data instanceof String))
                    order = data.tracks['data'].length + 1;

                var PTrack = {
                    'id': track.id,
                    'code': track.code,
                    'description': track.description,
                    'genre_id': track.genre_id,
                    'length': track.length,
                    'sort_order': order,
                    'mp3_url': track.mp3_url
                };

                if (typeof data === 'string' || data instanceof String) {
                    var PL = {'label': data, 'tracks': {'data': [PTrack]}};
                    $scope.userPlaylistCollection.store(PL);
                } else {
                    data.addProperty('tracks', PTrack);
                    data.update();
                }
            });
        };

    }]);

SiteApp.requires.push('SitePlayerApp');