var PlaylistFactory = angular.module('PlaylistFactory', ['EntityFactory']);

PlaylistFactory.factory('Playlist', ['Entity', function (Entity) {

	var url = '/api/v1/playlists';

	var Playlist = function (data) {
		Entity.apply(this, [url, data]);
	};

	Playlist.prototype = new Entity(url);

	return Playlist;
}]);