var UserSharedPlaylistsApp = angular.module('UserSharedPlaylistsApp', ['PlaylistFactory']);

UserSharedPlaylistsApp.controller('UserSharedPlaylistsController', ['$scope', '$http', '$window', '$modal', 'angularPlayer', 'Playlist', 'PleaseWaitModal', 'ConfirmationModal',
    function ($scope, $http, $window, $modal, angularPlayer, Playlist, PleaseWaitModal, ConfirmationModal) {
        $scope.playlist = new Playlist((typeof yessian === 'undefined') ? {} : yessian.playlist);

        $scope.Window = $window;
        $scope.Player = angularPlayer;

        $scope.currentTrack = null;
        $scope.formAction = '/api/v1/playlists/' + $scope.playlist.id + '/submitfeedback';
        $scope.like_link = '/api/v1/playlists/' + $scope.playlist.id + '/tracks/';

        $scope.getWaveform = function (Track) {
            var playingID = $scope.getCurrentPlaying();
            var mask = '';
            if (playingID == Track.id)
                mask = '-mask';

            var Size = 'lg';
            var Width = window.innerWidth;
            if (Width <= 1280)
                Size = 'md';
            if (Width <= 767)
                Size = 'sm';

            return Track.wf_url + '/' + Track.code + '_' + Size + mask + '.png';
        };

        $scope.setCurrentTrack = function ($event, Obj) {
            //$event.stopPropagation();
            if ($event.isDefaultPrevented()) return;

            if ($scope.currentTrack == null) {
                $scope.currentTrack = Obj;
                return;
            }
            if ($scope.currentTrack.id == Obj.id) {
                $scope.currentTrack = null;
                return;
            }
            $scope.currentTrack = Obj;
        };

        $scope.getCurrentPlaying = function () {
            var track = angularPlayer.getCurrentTrack();
            return track;
        };

        $scope.trackToggleLike = function (e, track) {
            var Idx = $scope.playlist.tracks.data.indexOf(track);
            if (Idx != -1) {
                $scope.playlist.tracks.data[Idx].liked = ($scope.playlist.tracks.data[Idx].liked == 1) ? 0 : 1;

                var endpoint = $scope.like_link + track.id + '/like';

                $http({
                    url: endpoint,
                    method: 'POST',
                    headers: {"Content-Type": "application/json"},
                    data: {'track_id' : track.id }
                }).success(function (data) {
                }).error(function () {
                    $scope.playlist.tracks.data[Idx].liked = ($scope.playlist.tracks.data[Idx].liked == 1) ? 0 : 1;
                });
            }
        };

        $scope.setFeedback = function () {
            var SharePlaylistModal = $modal.open({
                templateUrl: 'userPlaylistFeedbackModal',
                controller: 'ShareUserPlaylistController',
                size: 'lg',
                resolve: {
                    playlist: function () {
                        return playlist;
                    }
                }
            });

            SharePlaylistModal.result.then(function (data) {
                data.update();
            });
        };
    }]);
SiteApp.requires.push('UserSharedPlaylistsApp');