var ContactFactory = angular.module('ContactFactory', ['EntityFactory']);

ContactFactory.factory('Contact', ['$http', 'Entity', function ($http, Entity) {

	var url = '/api/v1/contacts';

	var Contact = function (data) {
		Entity.apply(this, [url, data]);
	};

	Contact.prototype = new Entity(url);


	Contact.prototype.addAddress = function() {
		this.addProperty('addresses', {type:'work'});
	};

	Contact.prototype.removeAddress = function (address) {
		this.removeProperty('addresses', address);
	};

	Contact.prototype.addEmail = function () {
		this.addProperty('email_addresses', {type:'work'})
	};

	Contact.prototype.removeEmail = function (email) {
		this.removeProperty('email_addresses', email);
	};

	Contact.prototype.addPhoneNumber = function () {
		this.addProperty('phone_numbers', {type:'work'});
	};

	Contact.prototype.removePhoneNumber = function (phone) {
		this.removeProperty('phone_numbers', phone);
	};

	Contact.prototype.addWebsite = function () {
		this.addProperty('websites', {type:'homepage'});
	};

	Contact.prototype.removeWebsite = function (website) {
		this.removeProperty('websites', website)
	};

	return Contact;


}]);