var TempoCollectionFactory = angular.module('TempoCollectionFactory', ['EntityCollectionFactory', 'TempoFactory']);

TempoCollectionFactory.factory('TempoCollection', ['EntityCollection', 'Tempo', function (EntityCollection, Tempo) {

	var url = '/api/v1/tempos';
	var name = 'tempos';

	var TempoCollection = function (initialTemposData) {
		EntityCollection.apply(this, [url, name, Tempo, initialTemposData]);
	};

	TempoCollection.prototype = new EntityCollection(url, name);

	return TempoCollection;

}]);