var MoodFactory = angular.module('MoodFactory', ['EntityFactory']);

MoodFactory.factory('Mood', ['Entity', function (Entity) {

	var url = '/api/v1/moods';

	var Mood = function (data) {
		Entity.apply(this, [url, data]);
	};

	Mood.prototype = new Entity(url);

	return Mood;
}]);