var MoodSubCollectionFactory = angular.module('MoodSubCollectionFactory', ['EntityCollectionFactory', 'MoodSubFactory']);

MoodSubCollectionFactory.factory('MoodSubCollection', ['EntityCollection', 'MoodSub', function (EntityCollection, MoodSub) {

	var url = '/api/v1/mood_subs';
	var name = 'mood_subs';

	var MoodSubCollection = function (initialMoodSubsData) {
		EntityCollection.apply(this, [url, name, MoodSub, initialMoodSubsData]);
	};

	MoodSubCollection.prototype = new EntityCollection(url, name);

	return MoodSubCollection;

}]);