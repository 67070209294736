var MoodSubFactory = angular.module('MoodSubFactory', ['EntityFactory']);

MoodSubFactory.factory('MoodSub', ['Entity', function (Entity) {

	var url = '/api/v1/mood_subs';

	var MoodSub = function (data) {
		Entity.apply(this, [url, data]);
	};

	MoodSub.prototype = new Entity(url);

	return MoodSub;
}]);