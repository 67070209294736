var contactCollection = angular.module('ContactCollectionFactory', ['EntityCollectionFactory', 'ContactFactory']);

contactCollection.factory('ContactCollection', ['EntityCollection', 'Contact', function(EntityCollection, Contact) {

	var url = '/api/v1/contacts';
	var name = 'contacts';

	var ContactCollection = function(contacts) {
		EntityCollection.apply(this, [url, name, Contact, contacts]);
		this.selectedSortBy = this.availableSortBys[0];
		this.selectedContactType = this.availableContactTypes[0];
		this.selectedRole = null;
		this.selectedAddressBook = null;
		this.selectedGenre = null;

	};

	ContactCollection.prototype = new EntityCollection(url, name);

	ContactCollection.prototype.getFilters = function () {
		var filters = {};

		filters.includes = 'genres,addresses,roles';

		if (this.selectedContactType.filter) {
			filters.type = this.selectedContactType.filter;
		}

		if (this.selectedRole) {
			filters.roles = this.selectedRole.id;
		}

		if (this.selectedGenre) {
			filters.genres = this.selectedGenre.id;
		}

		if (this.selectedAddressBook) {
			filters.address_books = this.selectedAddressBook.id;
		}

		return filters;
	};

	ContactCollection.prototype.availableSortBys =  [
		{'name':'Sort By Name', 'params':{'orderBy':'name', 'order':'asc'}},
		{'name':'Sort By Date Modified', 'params':{'orderBy':'updated_at', 'order':'desc'}}
	];

	ContactCollection.prototype.availableContactTypes = [
		{'name':'Show All Contacts', 'filter':null},
		{'name':'Show People', 'filter':'person'},
		{'name':'Show Companies', 'filter':'company'}
	];

	return ContactCollection;
}]);