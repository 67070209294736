angular.module('ConfirmationModal', ['ui.bootstrap'])

	.service('ConfirmationModal', ['$modal', function($modal) {
		return {
			show: function (title, message) {
				return $modal.open({
					template: '<div class="modal-header"><h3>{{title}}</h3></div><div class="modal-body">{{message}}</div></div></div><div class="modal-footer"><button class="btn btn-danger" data-ng-click="cancel()" data-ng-disabled="busy">Cancel</button><button class="btn btn-success" data-ng-click="confirm()" data-ng-disabled="busy">Confirm</button></div>',
					resolve: {
						title: function () { return title; },
						message: function () { return message; }
					},
					controller: ['$scope', '$modalInstance', 'title', 'message', function ($scope, $modalInstance, title, message) {
						$scope.title = title;
						$scope.message = message;
						$scope.cancel = function () {
							$modalInstance.dismiss('cancel');
						};
						$scope.confirm = function () {
							$modalInstance.close();
						};
					}]
				});
			}
		};
	}]);