var MusicSearchApp = angular.module('MusicSearchApp', ['TrackCollectionFactory', 'ContactCollectionFactory', 'InstrumentCollectionFactory', 'InstrumentSubCollectionFactory', 'GenreCollectionFactory', 'GenreSubCollectionFactory', 'TempoCollectionFactory', 'MoodCollectionFactory', 'MusicUseCollectionFactory', 'MoodSubCollectionFactory', 'MusicUseSubCollectionFactory', 'PlaylistFactory', 'PlaylistCollectionFactory', 'ViewContactModal']);
//'ui.select', 'ui.bootstrap', 'ngAnimate', 'checklist-model', 'angularSoundManager', 'ui-rangeSlider',

MusicSearchApp.filter('secondsToDateTime', [function () {
    return function (seconds) {
        return SiteUtils.formatSeconds(seconds, true);
        //return new Date(1970, 0, 1).setSeconds(seconds);
    };
}]);

MusicSearchApp.filter('getSubs', function () {
    return function (items, id, parent_id) {
        var filtered = [];
        for (var i = 0; i < items.length; i++) {
            if (id == items[i][parent_id])
                filtered.push(items[i]);
        }
        return filtered;
    };
});


MusicSearchApp.controller('MusicSearchController', ['$scope', '$window', '$modal', '$timeout', '$location', '$filter', 'angularPlayer', 'PleaseWaitModal', 'ConfirmationModal', 'EntityModal', 'TrackCollection', 'ContactCollection', 'InstrumentCollection', 'InstrumentSubCollection', 'GenreCollection', 'GenreSubCollection', 'TempoCollection', 'MoodCollection', 'MusicUseCollection', 'MoodSubCollection', 'MusicUseSubCollection', 'Playlist', 'PlaylistCollection', 'ViewContactModal',
    function ($scope, $window, $modal, $timeout, $location, $filter, angularPlayer, PleaseWaitModal, ConfirmationModal, EntityModal, TrackCollection, ContactCollection, InstrumentCollection, InstrumentSubCollection, GenreCollection, GenreSubCollection, TempoCollection, MoodCollection, MusicUseCollection, MoodSubCollection, MusicUseSubCollection, Playlist, PlaylistCollection, ViewContactModal) {
        $scope.Utils = SiteUtils;
        $scope.Window = $window;
        $scope.Player = angularPlayer;

        $timeout(function () {
//            $('#contMSPanes').find('.track-row').draggable({ helper: "clone" });
        });


        if (yessian.agencies) {
            $scope.agencies = new ContactCollection(yessian.agencies);
        }

        if (yessian.composers) {
            $scope.composers = new ContactCollection(yessian.composers);
        }


        $scope.tracks = new TrackCollection({});


        $scope.childEntityMatrix = [];

//region Collections
        $scope.genres = new GenreCollection({});
        $scope.genres.get();

        $scope.genreSubs = new GenreSubCollection({});
        $scope.genreSubs.get();
        /*
        $scope.$watchCollection('genreSubs.entities', function () {
            $scope.genreSubsMatrix = SiteUtils.matrixList($scope.genreSubs.entities, 3);
        });
        */


        $scope.tempos = new TempoCollection({});
        $scope.tempos.get();


        $scope.instruments = new InstrumentCollection({});
        $scope.instruments.get();
        $scope.instrumentSubs = new InstrumentSubCollection({});
        $scope.instrumentSubs.get();
        /*
        $scope.$watchCollection('instrumentSubs.entities', function () {
            $scope.instrumentSubsMatrix = SiteUtils.matrixList($scope.instrumentSubs.entities, 3);
        });
        */

        $scope.moods = new MoodCollection([]);
        $scope.moods.get();
        $scope.moodSubs = new MoodSubCollection({});
        $scope.moodSubs.get();
        /*
        $scope.$watchCollection('moodSubs.entities', function () {
            $scope.moodSubsMatrix = SiteUtils.matrixList($scope.moodSubs.entities, 3);
        });
        */


        $scope.musicUses = new MusicUseCollection([]);
        $scope.musicUses.get();
        $scope.musicUseSubs = new MusicUseSubCollection({});
        $scope.musicUseSubs.get();
        /*
        $scope.$watchCollection('musicUseSubs.entities', function () {
            $scope.musicUseSubsMatrix = SiteUtils.matrixList($scope.musicUseSubs.entities, 3);
        });
        */

        /*
        if ( $location.search().genre_id ) {
            $scope.tracks.selectedGenre = $scope.genres.entityWithId($location.search().genre_id);
            //$scope.genre = { open : true };
        }
        */

        $scope.userPlaylistCollection = new PlaylistCollection([]);
        $scope.userPlaylistCollection.selectedCats = ['user_playlist'];
        $scope.userPlaylistCollection.get(true);


//endregion

        //region Panes
        $scope.dndHandler = {
            dragItems : [],
            fDragActive : false,
            handleDragStart : function(e){
                if ( !$scope.panePlaylists.fPlaylistOpen || $scope.panePlaylists.selectedIndex == -1 ) {
                    e.preventDefault();
                    return false;
                }

                this.style.opacity = '0.7';
                var trackIdx = e.currentTarget.getAttribute('data-track-index');
                e.dataTransfer.setData('track_index', trackIdx);
                $scope.dndHandler.fDragActive = true;
            },

            handleDragEnd : function(e){
                this.style.opacity = '1.0';
                $scope.dndHandler.fDragActive = false;
            },

            handleDragOver : function (e) {
                e.preventDefault(); // Necessary. Allows us to drop.
                if ( $scope.dndHandler.fDragActive == false )
                    return;

                this.style.backgroundColor = '#ffa473';
                e.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.
                return false;
            },

            handleDragLeave : function (e) {
                e.preventDefault(); // Necessary. Allows us to drop.
                this.style.backgroundColor = '';
                return false;
            },

            handleDrop : function(e){
                e.preventDefault();
                e.stopPropagation();
                if ( $scope.dndHandler.fDragActive == false )
                    return;

                this.style.backgroundColor = '';
                var dataText = e.dataTransfer.getData('track_index');
                $scope.$apply(function() {
                    $scope.dndAddToPlaylist($scope.userPlaylistCollection.entities[$scope.panePlaylists.selectedIndex], $scope.tracks.entities[dataText]);
                });
            },
        };

        $scope.splitPaneProperties = {};
        $scope.panePlaylists = {
            fPlaylistOpen : false,
            selectedIndex : -1,
            setSelected : function(idx) {
                if ( this.selectedIndex == -1 &&this.selectedIndex != idx ) {
                    this.selectedIndex = idx;
                    return;
                }

                this.selectedIndex = -1;
            },
            togglePlaylist : function() {
                $('#contMSPanes').toggleClass('active');
                if ( this.fPlaylistOpen ) {
                    $('#contMSPanes').find('.ui-ms-sp-tracks').css('width', '100%');
                    $('#contMSPanes').find('.ui-ms-sp-playlists').css('width', '150px');
                    //                $scope.splitPaneProperties.firstComponentSize = '100';
                    //                $scope.splitPaneProperties.lastComponentSize = '0';
                } else {
                    $('#contMSPanes').find('.ui-ms-sp-tracks').css('width', '75%');
                    $('#contMSPanes').find('.ui-ms-sp-playlists').css('width', '25%');
                    //                $scope.splitPaneProperties.firstComponentSize = '75';
                    //                $scope.splitPaneProperties.lastComponentSize = '25%';
                }
                this.fPlaylistOpen = !this.fPlaylistOpen;
            },
            getPlaylistState : function() {
                return this.fPlaylistOpen;
            },

            trackCount : function() {
                if ( this.selectedIndex == -1 )
                    return 0;

                return $scope.userPlaylistCollection.entities[this.selectedIndex].tracks.data.length;
            },

            playlistTracks : function(newTracks) {
                if ($scope.panePlaylists.selectedIndex == -1 )
                    return false;

                if ( angular.isDefined(newTracks) ) {
                    $scope.userPlaylistCollection.entities[this.selectedIndex].tracks.data = newTracks;
                    return true;
                }

                var tracks = $scope.userPlaylistCollection.entities[this.selectedIndex].tracks.data;
                return tracks;
            }
        };

        if ($location.search().composer_id) {
            $scope.tracks.selectedComposer = $scope.composers.entityWithId($location.search().composer_id);
            $scope.composer = {open: true};
        }

        if ($location.search().agency_id) {
            $scope.tracks.selectedAgency = $scope.agencies.entityWithId($location.search().agency_id);
            $scope.agency = {open: true};
        }
//endregion

        $scope.trackLengthSlider = {
            options: {
                floor: 0,
                ceil: $scope.tracks.maxLength,
                showTicks: 120,
                onChange: function (sliderId, modelValue, highValue, pointerType) {
                    $scope.tracks.filtersChanged();
                },
                translate: function (value) {
                    var strTime = SiteUtils.formatSeconds(value, true);
                    if ( strTime == "10:00" )
                        strTime += "+";

                    return strTime;
                },
                showTicksValues: true
                //ticksArray: [0, 120, 240, 360, 480,600,720, 840]
            }
        };

        $scope.renderSlider = function () {
            $timeout(function () {
                $scope.$broadcast('rzSliderForceRender');
            });
        };

        $scope.getPlaylistCount = function () {
            return angularPlayer.getPlaylistCount();
        };

        $scope.getCurrentPlaying = function () {
            var track = angularPlayer.getCurrentTrack();
            return track;
        };

        $scope.tracks.get();

        $scope.filters = [];

        $scope.getWaveform = function (Track) {
            //@{{ (  + '_' + ( Window.innerWidth > 767 ? ( Window.innerWidth > 1280 ? 'lg' : 'md' ) : 'sm' ) + ( track.id == getCurrentPlaying() ?  '-mask' : '' ) + '.png' ) }}
            var playingID = $scope.getCurrentPlaying();
            var mask = '';
            if (playingID == Track.id)
                mask = '-mask';

            var Size = 'lg';
            var Width = window.innerWidth;
            if (Width <= 1280)
                Size = 'md';
            if (Width <= 767)
                Size = 'sm';

            return Track.wf_url + '/' + Track.code + '_' + Size + mask + '.png';
        };

        $scope.currentTrack = null;
        $scope.currentPlayingTrack = null;
        $scope.setCurrent = function ($event, Obj) {
            if ($event.isDefaultPrevented()) return;
            if ($scope.currentTrack == null) {
                $scope.currentTrack = Obj;
                return;
            }
            if ($scope.currentTrack.id == Obj.id) {
                $scope.currentTrack = null;
                return;
            }
            $scope.currentTrack = Obj;
        };

        //region Track Filters

        $scope.setSort = function (Sort) {
            $scope.tracks.selectedSortBy = $scope.tracks.availableSortBys[Sort];
            $scope.tracks.filtersChanged();
        };

        $scope.hasChildSelected = function (Obj, Category) {
            var Filtered = [];
            var Selected = [];
            switch (Category) {
                case 0 :
                    Filtered = $filter('getSubs')($scope.genreSubs.entities, Obj.id, 'genre_id');
                    Selected = $scope.tracks.selectedGenreSubs;
                    break;
                case 1 :
                    label = item.label;
                    break;
                case 2 :
                    Filtered = $filter('getSubs')($scope.instrumentSubs.entities, Obj.id, 'instrument_id');
                    Selected = $scope.tracks.selectedInstrumentSubs;
                    break;
                case 3 :
                    Filtered = $filter('getSubs')($scope.moodSubs.entities, Obj.id, 'mood_id');
                    Selected = $scope.tracks.selectedMoodSubs;
                    break;
                case 4 :
                    label = item.tempo;
                    break;
                case 5 :
                    Filtered = $filter('getSubs')($scope.musicUseSubs.entities, Obj.id, 'music_use_id');
                    Selected = $scope.tracks.selectedMusicUseSubs;
                    break;
                case 6 :
                    label = item.label;
                    break;
                case 7 :
                    label = item.full_name;
                    break;
                case 8 :
                    label = item.full_name;
                    break;
                case 9 :
                    label = item.label;
                    break;
                case 10 :
                    label = item.label;
                    break;
                default :
                    return;
                    break;
            }

            for (x = 0; x < Filtered.length; x++)
                if (Selected.indexOf(Filtered[x].id) > -1)
                    return true;

            return false;
        };

        $scope.toggleSoloMode = function(Cat) {
            switch (Cat) {
                case 0 : {
                    $scope.tracks.soloGenres = !$scope.tracks.soloGenres;
                    break;
                }
                case 1 : {
                    $scope.tracks.soloInstruments = !$scope.tracks.soloInstruments;
                    break;
                }
                case 2 : {
                    $scope.tracks.soloMusicUses = !$scope.tracks.soloMusicUses;
                    break;
                }
                case 3 : {
                    $scope.tracks.soloMoods = !$scope.tracks.soloMoods;
                    break;
                }
            }
            $scope.tracks.filtersChanged();
        };

        $scope.showSubFilter = function (Obj, Cat) {
            var refresh = true;
            var Entities = [];

            switch (Cat) {
                case 0 : {
                    if ($scope.genreSubs.entities.length > 0) {
                        $('#cont-music-search').find('.ui-tabs-content.ui-filter-genres').addClass('inactive');
                        $('#cont-music-search').find('.ui-tabs-subcontent.ui-filter-genre-subs').removeClass('inactive');
                        Entities = $filter('getSubs')($scope.genreSubs.entities, Obj.id, 'genre_id');
                        refresh = false;
                    }
                    break;
                }
                case 2 : {
                    if ($scope.instrumentSubs.entities.length > 0) {
                        $('#cont-music-search').find('.ui-tabs-content.ui-filter-instruments').addClass('inactive');
                        $('#cont-music-search').find('.ui-tabs-subcontent.ui-filter-instrument-subs').removeClass('inactive');
                        Entities = $filter('getSubs')($scope.instrumentSubs.entities, Obj.id, 'instrument_id');
                        refresh = false;
                    }
                    break;
                }
                case 3 : {
                    if ($scope.moodSubs.entities.length > 0) {
                        $('#cont-music-search').find('.ui-tabs-content.ui-filter-moods').addClass('inactive');
                        $('#cont-music-search').find('.ui-tabs-subcontent.ui-filter-mood-subs').removeClass('inactive');
                        Entities = $filter('getSubs')($scope.moodSubs.entities, Obj.id, 'mood_id');
                        refresh = false;
                    }
                    break;
                }
                case 5 : {
                    if ($scope.musicUseSubs.entities.length > 0) {
                        $('#cont-music-search').find('.ui-tabs-content.ui-filter-music-uses').addClass('inactive');
                        $('#cont-music-search').find('.ui-tabs-subcontent.ui-filter-music-use-subs').removeClass('inactive');
                        Entities = $filter('getSubs')($scope.musicUseSubs.entities, Obj.id, 'music_use_id');
                        refresh = false;
                    }
                    break;
                }

            }
            var Width = window.innerWidth;
            var Cols = 3;
            if (Width <= 767)
                Cols = 1;

            $scope.childEntityMatrix = SiteUtils.matrixList(Entities, Cols);
            //$scope.setVFilter(Obj, Cat, refresh);
        };

        $scope.finishSubFilter = function (Cat) {
            switch (Cat) {
                case 1 : {
                    $('#cont-music-search').find('.ui-tabs-subcontent.ui-filter-genre-subs').addClass('inactive');
                    $('#cont-music-search').find('.ui-tabs-content.ui-filter-genres').removeClass('inactive');
                    break;
                }
                case 6 : {
                    $('#cont-music-search').find('.ui-tabs-subcontent.ui-filter-instrument-subs').addClass('inactive');
                    $('#cont-music-search').find('.ui-tabs-content.ui-filter-instruments').removeClass('inactive');
                    break;
                }
                case 9 : {
                    if ($scope.musicUseSubs.entities.length > 0) {
                        $('#cont-music-search').find('.ui-tabs-subcontent.ui-filter-music-use-subs').addClass('inactive');
                        $('#cont-music-search').find('.ui-tabs-content.ui-filter-music-uses').removeClass('inactive');
                        refresh = false;
                    }
                    break;
                }
                case 10 : {
                    if ($scope.moodSubs.entities.length > 0) {
                        $('#cont-music-search').find('.ui-tabs-subcontent.ui-filter-mood-subs').addClass('inactive');
                        $('#cont-music-search').find('.ui-tabs-content.ui-filter-moods').removeClass('inactive');
                        refresh = false;
                    }
                    break;
                }

            }
            $scope.tracks.filtersChanged();
        };

        $scope.findVFilter = function (id, cat) {
            for (i = 0; i < $scope.filters.length; i++) {
                if ($scope.filters[i]['id'] == id && $scope.filters[i]['category'] == cat)
                    return i;
                if (cat == 7 && $scope.filters[i]['category'] == 7)
                    return i;
                if (cat == 8 && $scope.filters[i]['category'] == 8)
                    return i;
            }
            return -1;
        };

        $scope.findTFilter = function (collection, value) {
            for (i = 0; i < collection.length; i++) {
                if (collection[i] == value)
                    return i;
            }
            return -1;
        };

        $scope.setVFilter = function (item, category, refresh) {
            if (typeof item === 'undefined')
                item = {'id': -1, 'label': '', 'instrument': '', 'genre': '', 'tempo': '', 'full_name': ''};

            var idx = $scope.findVFilter(item.id, category);
            if (idx != -1) {
                $scope.removeVFilter(idx, false, true);
            } else {
                var label = "";
                switch (category) {
                    case 0 :
                        label = item.genre;
                        break;
                    case 1 :
                        label = item.label;
                        break;
                    case 2 :
                        label = item.instrument;
                        break;
                    case 3 :
                        label = item.label;
                        break;
                    case 4 :
                        label = item.tempo;
                        break;
                    case 5 :
                        label = item.label;
                        break;
                    case 6 :
                        label = item.label;
                        break;
                    case 7 :
                        label = item.full_name;
                        break;
                    case 8 :
                        label = item.full_name;
                        break;
                    case 9 :
                        label = item.label;
                        break;
                    case 10 :
                        label = item.label;
                        break;
                    default :
                        return;
                        break;
                }
                $scope.filters.push({'id': item.id, 'label': label, 'category': category});
            }
            if (refresh)
                $scope.tracks.filtersChanged();
        };

        $scope.removeVFilter = function (idx, fIsSub, refresh) {
            var filter = $scope.filters[idx];
            $scope.filters.remove(idx);
            var filter_idx = -1;
            switch (filter.category) {
                case 0 :
                    filter_idx = $scope.findTFilter($scope.tracks.selectedGenres, filter.id);
                    break;
                case 1 :
                    filter_idx = $scope.findTFilter($scope.tracks.selectedGenreSubs, filter.id);
                    break;
                case 2 :
                    filter_idx = $scope.findTFilter($scope.tracks.selectedInstruments, filter.id);
                    break;
                case 3 :
                    filter_idx = $scope.findTFilter($scope.tracks.selectedMoods, filter.id);
                    break;
                case 4 :
                    filter_idx = $scope.findTFilter($scope.tracks.selectedTempos, filter.id);
                    break;
                case 5 :
                    filter_idx = $scope.findTFilter($scope.tracks.selectedMusicUses, filter.id);
                    break;
                case 6 :
                    filter_idx = $scope.findTFilter($scope.tracks.selectedInstrumentSubs, filter.id);
                    break;
                case 7 :
                    delete $scope.tracks.selectedComposer;
                    break;
                case 8 :
                    delete $scope.tracks.selectedAgency;
                    break;
                case 9 :
                    filter_idx = $scope.findTFilter($scope.tracks.selectedMusicUseSubs, filter.id);
                    break;
                case 10 :
                    filter_idx = $scope.findTFilter($scope.tracks.selectedMoodSubs, filter.id);
                    break;
                default :
                    return;
                    break;
            }
            if (filter_idx != -1) {
                switch (filter.category) {
                    case 0 : {
                        $scope.tracks.selectedGenres.remove(filter_idx);
                        break;
                    }
                    case 1 :
                        $scope.tracks.selectedGenreSubs.remove(filter_idx);
                        break;
                    case 2 : {
                        $scope.tracks.selectedInstruments.remove(filter_idx);
                        break;
                    }
                    case 3 : {
                        $scope.tracks.selectedMoods.remove(filter_idx);
                        break;
                    }
                    case 4 : {
                        $scope.tracks.selectedTempos.remove(filter_idx);
                        break;
                    }
                    case 5 : {
                        $scope.tracks.selectedMusicUses.remove(filter_idx);
                        break;
                    }
                    case 6 :
                        $scope.tracks.selectedInstrumentSubs.remove(filter_idx);
                        break;
                    case 9 :
                        $scope.tracks.selectedMusicUseSubs.remove(filter_idx);
                        break;
                    case 10 :
                        $scope.tracks.selectedMoodSubs.remove(filter_idx);
                        break;
                    default :
                        return;
                        break;
                }
            }
            if ( !fIsSub ) {
                switch (filter.category) {
                    case 0 : {
                        //var Filtered = $filter('getSubs')($scope.genreSubs.entities, filter.id, 'genre_id');
                        for (sub_idx = 0; sub_idx < $scope.genreSubs.entities.length; sub_idx++) {
                            if ( $scope.tracks.selectedGenreSubs.indexOf($scope.genreSubs.entities[sub_idx].id) > -1 &&
                                $scope.genreSubs.entities[sub_idx]['genre_id'] == filter.id ) {
                                sub_filter_idx = $scope.findVFilter($scope.genreSubs.entities[sub_idx].id, 1);
                                if (sub_filter_idx != -1) {
                                    $scope.removeVFilter(sub_filter_idx, true, false);
                                }
                            }

                        }
                        break;
                    }
                    case 1 :
                        break;
                    case 2 : {
                        //var Filtered = $filter('getSubs')($scope.instrumentSubs.entities, filter.id, 'instrument_id');
                        for (sub_idx = 0; sub_idx < $scope.instrumentSubs.entities.length; sub_idx++) {
                            if ( $scope.tracks.selectedInstrumentSubs.indexOf($scope.instrumentSubs.entities[sub_idx].id) > -1
                                && $scope.instrumentSubs.entities[sub_idx]['instrument_id'] == filter.id ) {
                                sub_filter_idx = $scope.findVFilter($scope.instrumentSubs.entities[sub_idx].id, 6);
                                if (sub_filter_idx != -1) {
                                    $scope.removeVFilter(sub_filter_idx, true,false);
                                }
                            }

                        }
                        break;
                    }
                    case 3 : {
                        //var Filtered = $filter('getSubs')($scope.moodSubs.entities, filter.id, 'mood_id');
                        for (sub_idx = 0; sub_idx < $scope.moodSubs.entities.length; sub_idx++) {
                            if ($scope.tracks.selectedMoodSubs.indexOf($scope.moodSubs.entities[sub_idx].id) > -1 &&
                                $scope.moodSubs.entities[sub_idx]['mood_id'] == filter.id ) {
                                sub_filter_idx = $scope.findVFilter($scope.moodSubs.entities[sub_idx].id, 10);
                                if (sub_filter_idx != -1) {
                                    $scope.removeVFilter(sub_filter_idx, true,false);
                                }
                            }

                        }
                        break;
                    }
                    case 4 :
                        break;
                    case 5 : {
                        //var Filtered = $filter('getSubs')($scope.musicUseSubs.entities, filter.id, 'music_use_id');
                        for (sub_idx = 0; sub_idx < $scope.musicUseSubs.entities.length; sub_idx++) {
                            if ($scope.tracks.selectedMusicUseSubs.indexOf($scope.musicUseSubs.entities[sub_idx].id) > -1 &&
                                $scope.musicUseSubs.entities[sub_idx]['music_use_id'] == filter.id ) {
                                sub_filter_idx = $scope.findVFilter($scope.musicUseSubs.entities[sub_idx].id, 9);
                                if (sub_filter_idx != -1) {
                                    $scope.removeVFilter(sub_filter_idx, true,false);
                                }
                            }
                        }
                        break;
                    }
                    case 6 :
                        break;
                    case 9 :
                        break;
                    case 10 :
                        break;
                }
            }

            if (refresh)
                $scope.tracks.filtersChanged();
        };

        $scope.clearVFilters = function () {
            var len = $scope.filters.length;
            while (len--) {
                $scope.removeVFilter(len, false,false);
            }

            $scope.tracks.selectedMinLength = 0;
            $scope.tracks.selectedMaxLength = $scope.tracks.maxLength;
            $scope.$broadcast('rzSliderForceRender');

            $scope.tracks.filtersChanged();
        };

        //endregion

        $scope.downloadTrack = function (track) {
            $modal.open({
                templateUrl: 'DownloadTrackModal',
                controller: 'DownloadTrackController',
                size: 'sm',
                resolve: {
                    track: function () {
                        return track;
                    }
                }
            });
        };

        $scope.addToPlaylist = function (track) {
            var AddToPlaylistModal = $modal.open({
                templateUrl: 'addToUserPlaylistModal',
                controller: 'AddToPlaylistController',
                size: 'lg',
                resolve: {
                    track: function () {
                        return track;
                    },
                    playlists: function () {
                        return $scope.userPlaylistCollection;
                    }
                }
            }).result.then(function (data) {
                var order = 1;
                if (!(typeof data === 'string' || !data instanceof String))
                    order = data.tracks['data'].length + 1;

                var PTrack = {
                    'id': track.id,
                    'code': track.code,
                    'description': track.description,
                    'genre_id': track.genre_id,
                    'length': track.length,
                    'sort_order': order,
                    'mp3_url': track.mp3_url
                };

                if (typeof data === 'string' || data instanceof String) {
                    var PL = {'label': data, 'tracks': {'data': [PTrack]}};
                    $scope.userPlaylistCollection.store(PL);
                } else {
                    data.addProperty('tracks', PTrack);
                    data.update().then(function(result) {
                        $scope.userPlaylistCollection.entityChanged(result.data);
                    });
                }
            });
        };

        $scope.viewContact = function (contact) {
            ViewContactModal.open(contact);
        };

        $scope.$on('track:id', function (event, data) {
            $scope.isLoading = true;
        });

        $scope.$on('music:isPlaying', function (event, data) {
            $timeout(function () {
                $scope.isPlaying = data;
                $scope.currentPlayingTrack = $scope.getCurrentPlaying();
            });
        });

        $scope.$on('track:progress', function (event, data) {
            $scope.isLoading = false;
        });

        $scope.licenseIsExpired = function (license) {
            if (!license.end_date) return false;
            return new Date(license.end_date) < new Date();
        };

//region Render Utils

        $scope.getTempoWidth = function () {
            var ContWidth = $('#cont-music-search').find('.ui-tabs-content.ui-filter-tempos').first()[0].offsetWidth;
            if ( ContWidth === undefined )
                return 0;

            var Tempo = $('#cont-music-search').find('.ui-filter-tempos .ui-item-cont-circle').first()[0];
            var Width = (Tempo !== undefined) ? ( Tempo.offsetWidth != 0 ? Tempo.offsetWidth : 180 ) : 180;

            if ( $scope.tempos.entities.length * Width > ContWidth )
                return 0;

            return ($scope.tempos.entities.length - 1) * Width;
        };

        $scope.getPlaylistPaneTop = function() {
            var pageOffset = $window.pageYOffset;
            var SearchHeight = $('#cont-music-search').find('.ui-cont-ms-filters').height();
            var HeaderHeight = $('#contSiteHeader').height();
            var TotalPadding = 20;
            if ( pageOffset > ( SearchHeight + HeaderHeight + TotalPadding ) )
                return pageOffset - ( SearchHeight + HeaderHeight + TotalPadding );

            return 0;
        };


        $scope.getPlaylistPaneClass = function(fIsOpen) {
            var Classes = '',
            pageOffset = $window.pageYOffset,
            SearchHeight = $('#cont-music-search').find('.ui-cont-ms-filters').height(),
            HeaderHeight = $('#contSiteHeader').height(),
            PlaylistHeight = $('#cont-music-search').find('.ui-ms-spw-playlists').height(),
            TracksHeight = $('#cont-music-search').find('.ui-ms-spw-tracks').height(),
            TotalPadding = 20;
            if ( pageOffset > ( SearchHeight + HeaderHeight + TotalPadding ) ) {
                if ( !fIsOpen ) {
                    Classes = ' fx-scrolling';
                } else {
                    if ( TracksHeight - ( pageOffset - ( SearchHeight + HeaderHeight + TotalPadding ) ) < PlaylistHeight )
                        Classes = ' fx-sticky-bot';
                    else
                        Classes = ' fx-scrolling';
                }
            }
//                return pageOffset - ( SearchHeight + HeaderHeight + TotalPadding );

            return Classes;
        };

        $scope.getPlaylistPaneHeight = function() {
            var PLHeight = $('#cont-my-playlists').find('.ui-ms-spw-playlists').height();
            if ( PLHeight > 350 )
                return PLHeight + 15 + 'px';

            return '350px';
        };

//endregion

//region Playlist Controls
        $scope.sortableOptions = {
            update: function(e, ui) {
            },
            stop :function (e,ui) {
                var listing = ui.item.parent().sortable("toArray");
                $scope.userPlaylistCollection.entities[$scope.panePlaylists.selectedIndex].tracks.data =
                    SiteUtils.updateSortOrder($scope.userPlaylistCollection.entities[$scope.panePlaylists.selectedIndex].tracks.data, listing);
                $scope.userPlaylistCollection.entities[$scope.panePlaylists.selectedIndex].update();
            }
        };


        $scope.playlistCreate = function(e) {
            e.preventDefault();
            e.stopPropagation();

            var modalInstance = EntityModal.addEntity($scope.userPlaylistCollection, 'editUserPlaylistModal');
            modalInstance.result.then(function (newEntity) { });
        };

        $scope.playlistGetFeedback = function(e, plIdx) {
            e.preventDefault();
            e.stopPropagation();

            if ( plIdx == -1 )
                return;

            var SharePlaylistModal = $modal.open({
                templateUrl: 'userPlaylistFeedbackModal',
                controller: 'UserPlaylistFeedbackController',
                size: 'lg',
                resolve: {
                    playlist: function () {
                        return $scope.userPlaylistCollection.entities[plIdx];
                    }
                }
            });
        };

        $scope.dndAddToPlaylist = function (playlist, track) {
            var order = playlist.tracks['data'].length + 1;

            var PTrack = {
                'id': track.id,
                'code': track.code,
                'description': track.description,
                'genre_id': track.genre_id,
                'length': track.length,
                'sort_order': order,
                'mp3_url': track.mp3_url
            };

            playlist.addProperty('tracks', PTrack);
            playlist.update().then(function(result) {
                $scope.userPlaylistCollection.entityChanged(result.data);
            });
        };

        $scope.playlistEdit = function(e, plIdx) {
            e.preventDefault();
            e.stopPropagation();

            var modalInstance = EntityModal.editEntity($scope.userPlaylistCollection.entities[plIdx], 'editUserPlaylistModal');
            modalInstance.result.then(function (editedEntity) {
                angular.extend($scope.userPlaylistCollection.entities[plIdx], editedEntity);
            });
        };

        $scope.playlistTrackToggleLike = function (e, track) {
            e.preventDefault();
            e.stopPropagation();

            var currentIdx = $scope.panePlaylists.selectedIndex;
            if ( currentIdx == -1 )
                return;

            var Idx = $scope.userPlaylistCollection.entities[currentIdx].tracks.data.indexOf(track);
            if (Idx != -1 ) {
                $scope.userPlaylistCollection.entities[currentIdx].tracks.data[Idx].liked =
                    ( $scope.userPlaylistCollection.entities[currentIdx].tracks.data[Idx].liked == 1 ) ? 0 : 1;
                $scope.userPlaylistCollection.entities[currentIdx].update();
            }
        };

        $scope.playlistRemoveTrack = function(e, track) {
            e.preventDefault();
            e.stopPropagation();

            var currentIdx = $scope.panePlaylists.selectedIndex;
            if ( currentIdx == -1 )
                return;

            ConfirmationModal.show(
                'Remove this track?',
                'Are you sure you want to remove this track from this playlist?'
            ).result.then(
                    function () {
                        var trackIdx = $scope.userPlaylistCollection.entities[currentIdx].tracks.data.indexOf(track);
                        if (trackIdx != -1) {
                            $scope.userPlaylistCollection.entities[currentIdx].tracks.data.splice(trackIdx, 1);
                            $scope.userPlaylistCollection.entities[currentIdx].update();
                        }
                    }
                );
        };

        $scope.playlistDelete = function(e, plIdx) {
            e.stopPropagation();

            if ( plIdx == -1 )
                return;

            var currentIdx = $scope.panePlaylists.selectedIndex;

            ConfirmationModal.show('Delete This Playlist?', 'Are you sure you want to delete this playlist?')
                .result.then(function () {
                PleaseWaitModal.show();

                if ( plIdx == currentIdx )
                    $scope.panePlaylists.setSelected(-1);

                $scope.userPlaylistCollection.delete($scope.userPlaylistCollection.entities[plIdx]).
                success(function () {
                    PleaseWaitModal.hide();
                }).
                error(function(data) {
                    PleaseWaitModal.hide();
                    SiteUtils.alert("Whoops!", "There was an error deleting this playlist.");
                });
            });
        };

        $scope.playlistShare = function (e, plIdx) {
            e.preventDefault();
            e.stopPropagation();

            var SharePlaylistModal = $modal.open({
                templateUrl: 'shareUserPlaylistModal',
                controller: 'ShareUserPlaylistController',
                size: 'lg',
                resolve: {
                    playlist: function () {
                        return $scope.userPlaylistCollection.entities[plIdx];
                    }
                }
            });

            SharePlaylistModal.result.then(function (data) {
                data.update();
            });
        };

        $scope.playlistDuplicate = function (e, plIdx) {
            e.preventDefault();
            e.stopPropagation();

            var new_entity = new Playlist($scope.userPlaylistCollection.entities[plIdx]);
            delete new_entity.id;
            new_entity.label += " - Copy";
            $scope.userPlaylistCollection.store(new_entity).then(function() {
                SiteUtils.alert("","Your playlist has been duplicated");
            });
        };

        $scope.playlistDownload = function (e, plIdx) {
            e.preventDefault();
            e.stopPropagation();

            $modal.open({
                templateUrl: 'DownloadPlaylistModal',
                controller: 'DownloadPlaylistController',
                size: 'sm',
                resolve: {
                    playlist: function () {
                        return $scope.userPlaylistCollection.entities[plIdx];
                    }
                }
            });
        };


//endregion

    }]);

MusicSearchApp.controller('DownloadTrackController', ['$scope', '$modalInstance', 'track', function ($scope, $modalInstance, track) {
    $scope.Utils = SiteUtils;
    $scope.track = track;

    $scope.download = function (TrackCode, fAiff) {
        var Link = 'tracks/' + TrackCode + '/download';
        if (fAiff)
            Link += '?type=aiff';

        $scope.Utils.makeDownload(Link);
        SiteUtils.alert("","Your download will start shortly");
        $modalInstance.dismiss('cancel');
    };

    $scope.cancel = function () {
        $modalInstance.dismiss('cancel');
    };

}]);

SiteApp.requires.push('MusicSearchApp');