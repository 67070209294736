angular.module('PleaseWaitModal', ['ui.bootstrap'])

.service('PleaseWaitModal', ['$modal', function($modal) {

		var modal = null;

		return {

			show: function () {
				modal = $modal.open({
					template: '<div class="modal-header"><h3>One Moment...</h3></div><div class="modal-body"><div class="progress progress-striped active"><div class="progress-bar"  role="progressbar" aria-valuenow="45" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div></div></div>',
					keyboard: false,
					backdrop: 'static',
					controller: ['$scope', '$modalInstance', function ($scope, $modalInstance) {
						$scope.close = function () {
							$modalInstance.close();
						};
					}]
				});
			},

			hide: function () {
				if (modal) { modal.close(); }
				modal = null;
			}
		};
}]);