var InstrumentSubFactory = angular.module('InstrumentSubFactory', ['EntityFactory']);

InstrumentSubFactory.factory('InstrumentSub', ['Entity', function (Entity) {

	var url = '/api/v1/instrument_subs';

	var InstrumentSub = function (data) {
		Entity.apply(this, [url, data]);
	};

	InstrumentSub.prototype = new Entity(url);

	return InstrumentSub;
}]);