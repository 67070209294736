var GenreCollectionFactory = angular.module('GenreCollectionFactory', ['EntityCollectionFactory', 'GenreFactory']);

GenreCollectionFactory.factory('GenreCollection', ['EntityCollection', 'Genre', function (EntityCollection, Genre) {

	var url = '/api/v1/genres';
	var name = 'genres';

	var GenreCollection = function (initialGenresData, fLoadSubs) {
		EntityCollection.apply(this, [url, name, Genre, initialGenresData, fLoadSubs]);
	};

	GenreCollection.prototype = new EntityCollection(url, name);

	return GenreCollection;

}]);