LinksApp.controller('ShowLinkController', ['$scope', '$http', 'angularPlayer', 'PleaseWaitModal', function ($scope, $http, angularPlayer, PleaseWaitModal) {

    $scope.folder = yessian.folder;
    $scope.link = yessian.link;

    flowplayer(function (api, root) {
        $scope.flowplayer = api;
        api.on("load", function () {
            angularPlayer.pause();
        });
        api.on("resume", function () {
            angularPlayer.pause();
        })
    });

    $scope.$on('music:isPlaying', function (event, data) {
        if (data && $scope.flowplayer) {
            $scope.flowplayer.pause();
        }
    });


    $scope.downloadLink = function (link) {
        PleaseWaitModal.show();
        var url = '/api/v1/links/' + link.id + '?action=download';
        $scope.requestDownload(url);
    };


    $scope.downloadFile = function (link, file) {
        PleaseWaitModal.show();
        var url = '/api/v1/links/' + link.id + '/files/' + file.id + '?action=download';
        $scope.requestDownload(url);
    };


    $scope.requestDownload = function (url) {
        return $http({
            method: 'GET',
            url: url,
            headers: {"Content-Type": "application/json"}
        }).success(function (data) {
            location.href = data;
            PleaseWaitModal.hide();
        }).error(function (data) {
            PleaseWaitModal.hide();
            $scope.errors = data.errors;
        });
    };

}]);