var LinkCollectionFactory = angular.module('LinkCollectionFactory', ['EntityCollectionFactory', 'LinkFactory']);

LinkCollectionFactory.factory('LinkCollection', ['EntityCollection', 'Link', function (EntityCollection, Link) {

	var url = '/api/v1/links';
	var name = 'links';

	var LinkCollection = function (initialLinksData) {
		EntityCollection.apply(this, [url, name, Link, initialLinksData]);
		this.selectedSortBy = this.availableSortBys[0];
	};

	LinkCollection.prototype = new EntityCollection(url, name);


	LinkCollection.prototype.availableSortBys =  [
		{'name':'Sort By Date Added', 'params':{'orderBy':'created_at','order':'desc'}},
		{'name':'Sort By Title', 'params':{'orderBy':'title','order':'asc'}}
	];


	return LinkCollection;

}]);