var PlaylistCollectionFactory = angular.module('PlaylistCollectionFactory', ['EntityCollectionFactory', 'PlaylistFactory']);

PlaylistCollectionFactory.factory('PlaylistCollection', ['EntityCollection', 'Playlist', function (EntityCollection, Playlist) {

	var url = '/api/v1/playlists';
	var name = 'playlists';

	var PlaylistCollection = function (initialData) {
		EntityCollection.apply(this, [url, name, Playlist, initialData]);
		this.selectedCats = [];
	};

	PlaylistCollection.prototype = new EntityCollection(url, name);

	PlaylistCollection.prototype.availableSortBys =  [
		{'name':'Name', 'params':{'orderBy':'label', 'order':'asc'}},
		{'name':'Last Updated', 'params':{'orderBy':'updated_at', 'order':'desc'}},
	];

	PlaylistCollection.prototype.getFilters = function () {
		var filters = {
			'catagory[]': this.selectedCats
		};

		return filters;
	};

	return PlaylistCollection;
}]);

PlaylistCollectionFactory.controller('AddToPlaylistController', ['$scope', '$modalInstance', 'track', 'playlists', function($scope, $modalInstance, track, playlists) {

	$scope.track = track;
	$scope.playlists = playlists;
	$scope.selected_playlist = null;
	if ( playlists.entities.length > 0 ) {
		var idx = 0;
		var newest = playlists.entities[0]['last_updated'];
		$.each(playlists.entities, function(tIdx, tVal){
			if ( new Date (tVal['last_updated']) > new Date (newest) ) {
				idx = tIdx;
				newest = tVal['last_updated'];
			}
		});
		$scope.selected_playlist = playlists.entities[idx];
	}
	$scope.label = "";

	$scope.playlistSelected = function($item, obj) {
	    $scope.selected_playlist = obj;
	};

	$scope.ok = function() {
		if ($scope.label != "" )
			$modalInstance.close($scope.label);
		else
			$modalInstance.close($scope.selected_playlist);
	};

	$scope.cancel = function () {
		$modalInstance.dismiss('cancel');
	};

}]);

PlaylistCollectionFactory.controller('ShareUserPlaylistController', ['$scope', '$modalInstance', 'playlist', function($scope, $modalInstance, playlist) {
	$scope.entity = playlist;
	$scope.share_to = "";
	$scope.expirationDateIsOpen = false;
	$scope.busy = false;
	$scope.first_time = !playlist.share_enabled;

	if ( $scope.first_time )
		$scope.entity.share_enabled = true;

	/**
	 * For opening the date picker.
	 */
	$scope.openExpirationDate = function($event) {
		$event.preventDefault();
		$event.stopPropagation();
		$scope.expirationDateIsOpen = !$scope.expirationDateIsOpen;
	};

	$scope.ok = function() {
		$scope.entity['share_to'] = $scope.share_to;
		if ( !$scope.entity.share_enabled )
			$scope.entity.share_expiration = null;

		$modalInstance.close($scope.entity);
	};

	$scope.cancel = function () {
		$modalInstance.dismiss('cancel');
	};

}]);

PlaylistCollectionFactory.controller('UserPlaylistFeedbackController', ['$scope', '$modalInstance', 'playlist', function($scope, $modalInstance, playlist) {
	$scope.entity = playlist;
	$scope.busy = false;

	$scope.ok = function() {
		$modalInstance.close($scope.entity);
	};

	$scope.cancel = function () {
		$modalInstance.dismiss('cancel');
	};

}]);