var GenreSubCollectionFactory = angular.module('GenreSubCollectionFactory', ['EntityCollectionFactory', 'GenreSubFactory']);

GenreSubCollectionFactory.factory('GenreSubCollection', ['EntityCollection', 'GenreSub', function (EntityCollection, GenreSub) {

	var url = '/api/v1/genre_subs';
	var name = 'genre_subs';

	var GenreSubCollection = function (initialGenreSubsData) {
		EntityCollection.apply(this, [url, name, GenreSub, initialGenreSubsData]);
	};

	GenreSubCollection.prototype = new EntityCollection(url, name);

	return GenreSubCollection;

}]);