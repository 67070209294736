angular.module('ProgressModal', ['ui.bootstrap'])

	.service('ProgressModal', ['$modal', function($modal) {

		var modal = null;
		var progress = { value:0 };

		return {

			show: function (initialValue) {
				progress.value = initialValue;
				modal = $modal.open({
					template: '<div class="modal-header"><h3>One Moment...</h3></div><div class="modal-body"><div class="progress"><div class="progress-bar" data-ng-style="{width : ( progress.value + \'%\') }" style="width:0%"><b>{{ progress.value }}%</b></div></div>',
					controller: ['$scope', '$modalInstance', 'progress', function ($scope, $modalInstance, progress) {
						$scope.progress = progress;
						$scope.close = function () {
							$modalInstance.close();
						};
					}],
					keyboard: false,
					backdrop: 'static',
					resolve: {
						progress: function () {
							return progress;
						}
					}
				});
				return this;
			},

			setProgress: function (progressValue) {
				progress.value = progressValue;
			},

			hide: function () {
				if (modal) { modal.close(); }
				modal = null;
			}
		};
	}]);