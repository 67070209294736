var LinkFactory = angular.module('LinkFactory', ['EntityFactory']);

LinkFactory.factory('Link', ['Entity', function (Entity) {

	var url = '/api/v1/links';

	var Link = function (data) {
		this.files = {'data': []};
		Entity.apply(this, [url, data]);
	};

	Link.prototype = new Entity(url);

	return Link;
}]);
