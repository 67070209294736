var TrackFactory = angular.module('TrackFactory', ['EntityFactory', 'GenreFactory']);

TrackFactory.factory('Track', ['Entity', 'Genre', function (Entity, Genre) {

	var url = '/api/v1/tracks';

	var Track = function (data) {
		Entity.apply(this, [url, data]);
		if (! this.composers) { this.composers = {'data' : []}; }
		if (! this.genres) { this.genres = {'data' : []}; }
        if ( !this.genre ) { this.genre = new Genre(); } else {
            this.genre = new Genre(this.genre);
        }

        if  (! this.genre_subs) { this.genre_subs = {'data' : []}; }
		if  (! this.instruments) { this.instruments = {'data' : []}; }

		if  (! this.instrument_subs) { this.instrument_subs = {'data' : []}; }
		if  (! this.moods) { this.moods = {'data' : []}; }
		if  (! this.music_uses) { this.music_uses = {'data' : []}; }
	};

	Track.prototype = new Entity(url);

	Track.prototype.addComposer = function() {
		this.addProperty('composers', {});
	};

	Track.prototype.removeComposer = function (composer) {
		this.removeProperty('composers', composer);
	};

	return Track;
}]);