var MoodCollectionFactory = angular.module('MoodCollectionFactory', ['EntityCollectionFactory', 'MoodFactory']);

MoodCollectionFactory.factory('MoodCollection', ['EntityCollection', 'Mood', function (EntityCollection, Mood) {

	var url = '/api/v1/moods';
	var name = 'moods';

	var MoodCollection = function (initialMoodsData) {
		EntityCollection.apply(this, [url, name, Mood, initialMoodsData]);
	};

	MoodCollection.prototype = new EntityCollection(url, name);

	return MoodCollection;

}]);