var MusicUseSubCollectionFactory = angular.module('MusicUseSubCollectionFactory', ['EntityCollectionFactory', 'MusicUseSubFactory']);

MusicUseSubCollectionFactory.factory('MusicUseSubCollection', ['EntityCollection', 'MusicUseSub', function (EntityCollection, MusicUseSub) {

	var url = '/api/v1/music_use_subs';
	var name = 'music_use_subs';

	var MusicUseSubCollection = function (initialMusicUseSubsData) {
		EntityCollection.apply(this, [url, name, MusicUseSub, initialMusicUseSubsData]);
	};

	MusicUseSubCollection.prototype = new EntityCollection(url, name);

	return MusicUseSubCollection;

}]);