var MusicUseCollectionFactory = angular.module('MusicUseCollectionFactory', ['EntityCollectionFactory', 'MusicUseFactory']);

MusicUseCollectionFactory.factory('MusicUseCollection', ['EntityCollection', 'MusicUse', function (EntityCollection, MusicUse) {

	var url = '/api/v1/music_uses';
	var name = 'music_uses';

	var MusicUseCollection = function (initialData) {
		EntityCollection.apply(this, [url, name, MusicUse, initialData]);
	};

	MusicUseCollection.prototype = new EntityCollection(url, name);

	return MusicUseCollection;

}]);