angular.module('EntityCollectionFactory', []).factory('EntityCollection', ['$http', '$q', '$sce', function($http, $q, $sce) {

	var EntityCollection = function(url, entityName, entityFactory, entities) {
		this.url = url;
		this.name = entityName;
		this.entityFactory = entityFactory;
		this.entities = [];
		this.busy = false;
		this.current_page = 0;
		this.next_page = 0;
		this.total_pages = null;
		this.canceler = null;
		this.query = '';
		this.count = 100;
		this.total_count = 0;
		if (entities) {
			for (var i = 0; i < entities.length; i++) {
				var entity = new this.entityFactory(entities[i]);
				this.entities.push(entity);
			}
		}
	};


	EntityCollection.prototype.search = function (query) {
		this.query = query;
		this.next_page = 1;
		this.current_page = 1;
		this.total_pages = null;
		return this.get(true);
	};

	EntityCollection.prototype.clearQuery = function()
	{
		return this.search('');
	};

	EntityCollection.prototype.getFilters = function()
	{
		return {};
	};

	EntityCollection.prototype.filtersChanged = function()
	{
		return this.search(this.query);
	};

	EntityCollection.prototype.availableSortBys = function ()
	{
		return {};
	};


	EntityCollection.prototype.getParams = function () {
		var params = {};

		params.page = this.next_page;
		params.count = this.count;

		var filters = this.getFilters();
		for (filter in filters) {
			params[filter] = filters[filter]
		}

		if (this.query.length) {
			params.q = this.query;
		}

		if (this.selectedSortBy) {
			for (param in this.selectedSortBy.params) {
				params[param] = this.selectedSortBy.params[param];
			}
		}

		return params;
	};


	EntityCollection.prototype.get = function (clearEntities, updatesOnly) {
		this.busy = true;

		if (this.canceler) { this.canceler.resolve(); }
		this.canceler = $q.defer();

		var url = this.url;
		var params = this.getParams();
		//console.log('Get Params: ' + JSON.stringify(params) );

		var self = this;

		return $http({
			method:'GET',
			url:url,
			params: params,
			headers: { "Content-Type": "application/json" },
			timeout:this.canceler.promise,
			data: ''
		}).success(function(data) {

			var entities = data[self.name];

			if (updatesOnly) {
				for (var j = self.entities.length - 1; j >= 0; j--) {
					var found = false;
					for (var k = 0; k < entities.length; k++) {
						if (entities[k].id == self.entities[j].id) {
							found = true;
							break;
						}
					}
					if (!found) { self.entities.splice(j, 1); }
				}
			} else if (clearEntities) {
				self.entities = [];
			}

			for (var i = 0; i < entities.length; i++) {
				var entity = new self.entityFactory(entities[i]);
				self.entityChanged(entity);
			}
			if (data.meta && data.meta.pagination) {
				self.total_count = data.meta.pagination.total;
				self.current_page = data.meta.pagination.current_page;
				self.next_page = data.meta.pagination.current_page + 1;
				self.total_pages = data.meta.pagination.total_pages;
				if (self.next_page > self.total_pages) {
					self.next_page = self.total_pages;
				}
			}
			self.busy = false;
		}).error(function(data) {
			self.busy = false;
		});
	};


    EntityCollection.prototype.getWithChildren = function (clearEntities, updatesOnly) {
        this.busy = true;

        if (this.canceler) { this.canceler.resolve(); }
        this.canceler = $q.defer();

        var url = this.url;
        var params = this.getParams();
        //console.log('Get Params: ' + JSON.stringify(params) );

        var self = this;

        return $http({
            method:'GET',
            url:url,
            params: params,
            headers: { "Content-Type": "application/json" },
            timeout:this.canceler.promise,
            data: ''
        }).success(function(data) {

            var entities = data[self.name];

            if (updatesOnly) {
                for (var j = self.entities.length - 1; j >= 0; j--) {
                    var found = false;
                    for (var k = 0; k < entities.length; k++) {
                        if (entities[k].id == self.entities[j].id) {
                            found = true;
                            break;
                        }
                    }
                    if (!found) { self.entities.splice(j, 1); }
                }
            } else if (clearEntities) {
                self.entities = [];
            }

            for (var i = 0; i < entities.length; i++) {
                var entity = new self.entityFactory(entities[i]);
                entity.loadChildren();
                self.entityChanged(entity);
            }
            if (data.meta && data.meta.pagination) {
				self.total_count = data.meta.pagination.total;
                self.current_page = data.meta.pagination.current_page;
                self.next_page = data.meta.pagination.current_page + 1;
                self.total_pages = data.meta.pagination.total_pages;
                if (self.next_page > self.total_pages) {
                    self.next_page = self.total_pages;
                }
            }
            self.busy = false;
        }).error(function(data) {
			self.busy = false;
		});
    };

    EntityCollection.prototype.entityChanged = function (entity) {
		var original = this.entityWithId(entity.id);
		if (original) {
			angular.extend(original, entity);
		} else {
			this.entities.push(entity);
		}
	};

	EntityCollection.prototype.entityWithId = function (id) {
		var entityCount = this.entities.length;
		for (var i = 0; i < entityCount; i++) {
			if (this.entities[i].id == id) {
				return this.entities[i];
			}
		}
		return false;
	};


	EntityCollection.prototype.store = function(data) {
		this.busy = true;
		var self = this;
		return $http({
			method: 'POST',
			url: this.url,
			headers: { "Content-Type": "application/json" },
			data: data
		}).success(function(data) {
			var entity = new self.entityFactory(data);
			self.entities.push(entity);
			self.busy = false;
		}).error(function(data) {
			self.busy = false;
		})
	};


	EntityCollection.prototype.delete = function(entity) {
		this.busy = true;
		var self = this;
		return entity.delete().
			success(function() {
				self.busy = false;
				var index = self.entities.indexOf(entity);
				if (index != -1) {
					self.entities.splice(index, 1);
				}
			}).
			error(function () {
				self.busy = false;
			});
	};


	EntityCollection.prototype.nextPage = function() {
		if (this.busy) { return; }
		if (this.current_page=== this.total_pages) { return; }
		if (this.total_pages == null || this.total_pages === 0) { return; }
		return this.get(false);
	};


	EntityCollection.prototype.trustAsHtml = function (value) {
		return $sce.trustAsHtml(value);
	};


	return EntityCollection;

}]);
