var InstrumentFactory = angular.module('InstrumentFactory', ['EntityFactory']);

InstrumentFactory.factory('Instrument', ['Entity', function (Entity) {

	var url = '/api/v1/instruments';

	var Instrument = function (data) {
		Entity.apply(this, [url, data]);
	};

	Instrument.prototype = new Entity(url);

	return Instrument;
}]);