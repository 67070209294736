var GenreSubFactory = angular.module('GenreSubFactory', ['EntityFactory']);

GenreSubFactory.factory('GenreSub', ['Entity', function (Entity) {

	var url = '/api/v1/genre_subs';

	var GenreSub = function (data) {
		Entity.apply(this, [url, data]);
	};

	GenreSub.prototype = new Entity(url);

	return GenreSub;
}]);