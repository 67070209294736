var ViewContactModal = angular.module('ViewContactModal', ['ui.bootstrap']);

ViewContactModal.service('ViewContactModal', ['$modal', function ($modal) {

	return {
		open: function(contact) {
			return $modal.open({
				templateUrl: 'ViewContactModal',
				controller: 'ViewContactModalController',
				resolve: {
					contact: function () { return contact; }
				}
			})
		}
	}

}]);

ViewContactModal.controller('ViewContactModalController', ['$scope', '$modalInstance', 'contact', function ($scope, $modalInstance, contact) {
	$scope.contact = contact;

	$scope.ok = function () {
		$modalInstance.close();
	}
}]);