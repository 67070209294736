var InstrumentSubCollectionFactory = angular.module('InstrumentSubCollectionFactory', ['EntityCollectionFactory', 'InstrumentSubFactory']);

InstrumentSubCollectionFactory.factory('InstrumentSubCollection', ['EntityCollection', 'InstrumentSub',
	function (EntityCollection, InstrumentSub) {

	var url = '/api/v1/instrument_subs';
	var name = 'instrument_subs';

	var InstrumentSubCollection = function (initialData) {
		EntityCollection.apply(this, [url, name, InstrumentSub, initialData]);
	};

	InstrumentSubCollection.prototype = new EntityCollection(url, name);

	return InstrumentSubCollection;

}]);