var TrackCollectionFactory = angular.module('TrackCollectionFactory', ['EntityCollectionFactory', 'TrackFactory']);

TrackCollectionFactory.factory('TrackCollection', ['EntityCollection', 'Track', function (EntityCollection, Track) {

	var url = '/api/v1/tracks';
	var name = 'tracks';

	var TrackCollection = function (initialTracksData) {
		EntityCollection.apply(this, [url, name, Track, initialTracksData]);
		this.selectedMinLength = 0;
		this.selectedMaxLength = 600;
		this.maxLength = 600;
		this.selectedGenre = null;
		this.selectedGenres = [];
        this.selectedGenreSubs = [];
		this.selectedInstruments = [];
		this.selectedInstrumentSubs = [];
		this.selectedTempos = [];
		this.selectedMusicUses = [];
		this.selectedMusicUseSubs = [];
		this.selectedMoods = [];
		this.selectedMoodSubs = [];
		this.selectedComposerType = 'all';
		this.selectedGenreType = 'any';
		this.selectedSortBy = this.availableSortBys[0];
		this.count = 25;

	};

	TrackCollection.prototype = new EntityCollection(url, name);

	TrackCollection.prototype.availableSortBys =  [
		{'name':'Code', 'params':{'orderBy':'code', 'order':'desc'}},
		{'name':'Description', 'params':{'orderBy':'description', 'order':'asc'}},
		{'name':'Newest', 'params':{'orderBy':'created_at', 'order':'desc'}},
		{'name':'Oldest', 'params':{'orderBy':'created_at', 'order':'asc'}},
	];


	TrackCollection.prototype.calculateMinLength = function() {
		return this.selectedMinLength;
		var scale = Math.log(this.maxLength) / 100;
		return Math.ceil(Math.exp(scale*(this.selectedMinLength)) - 1);
	};

	TrackCollection.prototype.calculateMaxLength = function() {
		return this.selectedMaxLength;
		var scale = Math.log(this.maxLength) / 100;
		return Math.ceil(Math.exp(scale*(this.selectedMaxLength)) - 1);
	};

	TrackCollection.prototype.getFilters = function () {
		var filters = {
			'composer-type' : this.selectedComposerType,
			'genre-type' : this.selectedGenreType
		};

		if (this.selectedGenre) {
			filters['genre'] = this.selectedGenre.id;
		}


		if (this.selectedGenres.length) {
			filters['genres[]'] = this.selectedGenres;
		}

		if (this.selectedGenreSubs.length) {
			filters['genre_subs[]'] = this.selectedGenreSubs;
		}


		if (this.selectedInstruments.length) {
			filters['instruments[]'] = this.selectedInstruments;
		}

		if (this.selectedInstrumentSubs.length) {
			filters['instrument_subs[]'] = this.selectedInstrumentSubs;
		}



		if (this.selectedMoods.length) {
			filters['moods[]'] = this.selectedMoods;
		}

		if (this.selectedMoodSubs.length) {
			filters['mood_subs[]'] = this.selectedMoodSubs;
		}

		if (this.selectedMusicUses.length) {
			filters['music_uses[]'] = this.selectedMusicUses;
		}

		if (this.selectedMusicUseSubs.length) {
			filters['music_use_subs[]'] = this.selectedMusicUseSubs;
		}

		if (this.selectedTempos.length) {
			filters['tempos[]'] = this.selectedTempos;
		}

		if (this.selectedComposer) {
			filters['composers[]'] = this.selectedComposer.id;
		}

		if (this.selectedAgency) {
			filters['agencies[]'] = this.selectedAgency.id;
		}

		if (this.calculateMinLength()) {
			filters['min-length'] = this.calculateMinLength();
		}

		if (this.calculateMaxLength() < this.maxLength) {
			filters['max-length'] = this.calculateMaxLength();
		}

		return filters;
	};

	return TrackCollection;

}]);